import type { Core } from "@griddo/core";

import components from "./src/schemas/components";
import frontless from "./src/schemas/components/frontless";
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	themes,
} from "./src/schemas/config";
import {
	categories,
	pageContentType,
	simpleContentType,
} from "./src/schemas/content-types";
import { dataPacks, dataPacksCategories } from "./src/schemas/data-packs";
import modules from "./src/schemas/modules";
import templates from "./src/schemas/templates";

export default {
	schemas: {
		config: {
			menus,
			themes,
			languages,
			menuItems,
			moduleCategories,
		},

		ui: {
			components: { ...components, ...frontless },
			modules,
			templates,
		},

		contentTypes: {
			dataPacks,
			dataPacksCategories,
			structuredData: {
				...categories,
				...pageContentType,
				...simpleContentType,
			},
		},
	},
} satisfies Core.Config;
