import { Schema } from "@griddo/core";

import { CypherCardProps } from "@/autotypes";

const schema: Schema.Component<CypherCardProps> = {
	schemaType: "component",
	component: "CypherCard",
	displayName: "CypherCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Icon",
					type: "ComponentContainer",
					whiteList: ["Image"],
					key: "icon",
					helptext: "Upload your icon",
					hideable: true,
				},

				{
					type: "TextField",
					title: "Number",
					key: "number",
					placeholder: "Type a number",
					isMockup: true,
					hideable: true,
					humanReadable: true,
				},

				{
					type: "HeadingField",
					title: "Title",
					default: { content: "Lorem ipsum", tag: "h2" },
					key: "title",
					placeholder: "Type a title",
					advanced: true,
					hideable: true,
					isMockup: true,
					options: [
						{ label: "h2", value: "h2" },
						{ label: "h3", value: "h3" },
						{ label: "h4", value: "h4" },
					],
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Body",
					key: "body",
					placeholder: "Type a detail text",
					isMockup: true,
					hideable: true,
					humanReadable: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "RadioGroup",
					title: "Number size",
					key: "numberSize",
					helptext: "Choose the font size",
					options: [
						{ name: "small", title: "Small", value: "small" },
						{ name: "big", title: "Big", value: "big" },
					],
				},
			],
		},
	],

	default: {
		component: "CypherCard",
		icon: {
			component: "Image",
		},
		number: "000",
		title: { content: "Lorem ipsum", tag: "h2" },
		body: "Lorem ipsum paragraph",
		numberSize: "big",
	},

	thumbnails: {
		"1x": "/thumbnails/components/CypherCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/CypherCard/thumbnail@2x.png",
	},
};

export default schema;
