import { Schema } from "@griddo/core";

import { SlideProps } from "@/autotypes";

const schema: Schema.Component<SlideProps> = {
	schemaType: "component",
	component: "Slide",
	displayName: "Slide",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "RichText",
					title: "Title",
					key: "title",
					placeholder: "Type a title",
					helptext:
						"Write plain text and select the heading type. Use double curly brackets to color words. For example: Lorem {{ipsum}}",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Section Title",
					key: "sectionTitle",
					placeholder: "Type a intro",
					helptext:
						"Write plain text and select the heading type. Use double curly brackets to color words. For example: Lorem {{ipsum}}",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "TextField",
					title: "Content",
					key: "text",
					placeholder: "Type a content",
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					key: "card",
					title: "Card",
					whiteList: ["SlideCard"],
				},
			],
		},
	],

	default: {
		component: "Slide",
		card: {
			component: "SlideCard",
		},
		text: "Content for the Slide",
		sectionTitle: "Section title",
		title: "title",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Slide/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Slide/thumbnail@2x.png",
	},
};

export default schema;
