import { Schema } from "@griddo/core";
import { allModules } from "@schemas/presets";

import { ProjectDetailProps } from "@/autotypes";

const schema: Schema.Template<ProjectDetailProps> = {
	schemaType: "template",
	displayName: "Project Detail",
	component: "ProjectDetail",
	dataPacks: ["PROJECTS_PACK"],
	type: { label: "Project", value: "project", mode: "detail" },

	content: [
		// HEROS
		{
			type: "ComponentContainer",
			title: "Hero Project",
			whiteList: ["HeroProject"],
			key: "heroProject",
			hideable: true,
		},

		// QUICKFACTS
		{
			type: "UniqueCheck",
			title: "Quickfacts",
			key: "showQuickfacts",
			options: [{ title: "Show Quickfacts" }],
		},

		// INTRO
		{
			type: "RichText",
			key: "intro",
			title: "Intro",
			mandatory: true,
			helptext: "Shown on the distributor card",
			humanReadable: true,
		},

		// CATEGORIES
		{
			type: "MultiCheckSelectGroup",
			key: "categories",
			title: "Categories",
			elements: [
				{
					key: "clientCategory",
					source: "CLIENT_CATEGORY",
					placeholder: "Clients",
				},

				{
					key: "servicesCategory",
					source: "SERVICES_CATEGORY",
					placeholder: "Services",
				},

				{
					key: "industryCategory",
					source: "INDUSTRY_CATEGORY",
					placeholder: "Industry",
				},

				{
					key: "skillsCategory",
					source: "SKILLS_CATEGORY",
					placeholder: "Skills",
				},
			],
		},

		// IMAGE
		{
			type: "ImageField",
			key: "image",
			title: "Image",
		},

		// LOGO
		{
			type: "ImageField",
			key: "logo",
			title: "Client Logo",
		},

		// PROJECT STATE
		{
			type: "RadioGroup",
			key: "projectState",
			title: "Project State",
			options: [
				{ name: "wip", title: "Wip", value: "wip" },
				{ name: "finish", title: "Finished", value: "finish" },
			],
		},

		// OTHER PROJECT DATA
		{
			type: "FieldGroup",
			key: "otherProjectData",
			title: "Other project Data",
			fields: [
				{
					type: "TextField",
					key: "date",
					title: "Date",
					placeholder: "Type a date",
					humanReadable: true,
				},
				{
					type: "TagsField",
					key: "team",
					title: "Team",
				},
			],
		},

		// MAIN SECTION
		{
			type: "ComponentArray",
			title: "Main Section",
			contentType: "modules",
			key: "mainSection",
			whiteList: allModules,
		},
	],

	default: {
		type: "template",
		templateType: "ProjectDetail",
		projectState: "finish",
		date: "",
		team: [],
		categories: {
			clientCategory: [],
			industryCategory: [],
			servicesCategory: [],
			skillsCategory: [],
		},
		intro: "The lorem ipsum intro",
		showQuickfacts: true,
		heroProject: {
			component: "HeroProject",
			subtheme: "inverse",
			title: {
				content: "Content",
				tag: "h3",
			},
			content: "Content",
			image: {
				component: "FullImage",
			},
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProjectDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProjectDetail/thumbnail@2x.png",
	},
};

export default schema;
