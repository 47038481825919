import { Schema } from "@griddo/core";
import { allModules } from "@schemas/presets";

import { BasicTemplateProps } from "@/autotypes";

const schema: Schema.Template<BasicTemplateProps> = {
	schemaType: "template",
	displayName: "Basic",
	component: "BasicTemplate",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			contentType: "modules",
			maxItems: 1,
			whiteList: ["HeroVideo", "InnerHero", "CustomVideo"],
			key: "heroSection",
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			contentType: "modules",
			whiteList: allModules,
			key: "mainSection",
		},
	],

	default: {
		type: "template",
		templateType: "BasicTemplate",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/BasicTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/BasicTemplate/thumbnail@2x.png",
	},
};

export default schema;
