import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

import { AccordionCardProps } from "@/autotypes";

const schema: Schema.Component<AccordionCardProps> = {
	schemaType: "component",
	component: "AccordionCard",
	displayName: "AccordionCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					key: "media",
					title: "Media",
					whiteList: ["FullImage", "Video"],
				},

				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content text",
					isMockup: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "AccordionCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Lorem ipsum paragraph",
		media: {
			fullImage: {
				component: "FullImage",
			},
			video: {
				component: "Video",
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/AccordionCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/AccordionCard/thumbnail@2x.png",
	},
};

export default schema;
