import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import { ProjectDistributorProps } from "@/autotypes";

const schema: Schema.ContentTypeModule<ProjectDistributorProps> = {
	schemaType: "module",
	component: "ProjectDistributor",
	category: "distributors",
	dataPacks: ["PROJECTS_PACK"],
	displayName: "ProjectDistributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Intro",
					key: "intro",
					placeholder: "Type a intro",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ReferenceField",
					title: "Projects",
					key: "data",
					sources: [{ structuredData: "PROJECT" }],
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},

				{
					title: "Filters options",
					key: "filtersOptions",
					type: "CheckGroup",
					options: [{ name: "hideFilters", title: "Hide filters" }],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/ProjectDistributor/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/ProjectDistributor/layout2.png",
						},
					],
				},
				{ ...themeSelector },
				...verticalSpacing,
			],
		},
	],

	default: {
		component: "ProjectDistributor",
		hasDistributorData: true,
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		intro: "Lorem ipsum paragraph",
		link: {
			component: "Button",
			link: { text: "Saber más", linkType: "url", url: {}, modal: {} },
		},
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "default",
		filters: [],
		layout: "layout1",
		data: {
			sources: [{ structuredData: "PROJECT" }],
			mode: "auto",
			order: "alpha",
			quantity: 2,
		},
		hideFilters: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/ProjectDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/ProjectDistributor/thumbnail@2x.png",
	},
};

export default schema;
