import { Schema } from "@griddo/core";
import { anchor, themeSelector, verticalSpacing } from "@schemas/presets";

import { CustomVideoProps } from "@/autotypes";

const schema: Schema.Module<CustomVideoProps> = {
	schemaType: "module",
	component: "CustomVideo",
	category: "content",
	displayName: "CustomVideo",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Video",
					key: "video",
					type: "RadioGroup",
					options: [
						{ name: "coffee", value: "coffee", title: "Coffee and Lego cup" },
						{
							name: "coffee2",
							value: "coffee2",
							title: "Coffee and milk carton",
						},
						{
							name: "coffeeLaptop",
							value: "coffeeLaptop",
							title: "Coffee and laptop",
						},
					],
				},

				{
					type: "RichText",
					title: "Title",
					key: "title",
					placeholder: "Type a title",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
				{ ...themeSelector },
				...verticalSpacing,
			],
		},
	],

	default: {
		component: "CustomVideo",
		title: "Lorem ipsum",
		content: "Lorem ipsum paragraph",
		link: {
			component: "Button",
			link: { text: "Saber más", linkType: "url", url: {}, modal: {} },
			variant: "cta",
		},
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "inverse",
		veil: 0,
		video: "coffee",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CustomVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CustomVideo/thumbnail@2x.png",
	},
};

export default schema;
