import { Schema } from "@griddo/core";

import { DesignOpsGraphicProps } from "@/autotypes";

const schema: Schema.Component<DesignOpsGraphicProps> = {
	schemaType: "component",
	component: "DesignOpsGraphic",
	displayName: "DesignOps Graphic",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ArrayFieldGroup",
					title: "Steps",
					key: "steps",
					name: "Step",
					arrayType: "dropDown",
					fields: [
						{
							type: "TextField",
							title: "Title",
							key: "title",
							placeholder: "Type a title",
							mandatory: true,
							humanReadable: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
						},

						{
							type: "Wysiwyg",
							title: "Content",
							key: "content",
							placeholder: "Type a content",
							humanReadable: true,
						},
					],
				},
			],
		},
	],

	default: {
		component: "DesignOpsGraphic",
		steps: [],
	},

	thumbnails: {
		"1x": "/thumbnails/components/DesignOpsGraphic/thumbnail@1x.png",
		"2x": "/thumbnails/components/DesignOpsGraphic/thumbnail@2x.png",
	},
};

export default schema;
