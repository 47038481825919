import { Schema } from "@griddo/core";
import { anchor, themeSelector, verticalSpacing } from "@schemas/presets";

import { MasonryAudiovisualProps } from "@/autotypes";

const schema: Schema.Module<MasonryAudiovisualProps> = {
	schemaType: "module",
	component: "MasonryAudiovisual",
	category: "masonry",
	displayName: "MasonryAudiovisual",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Elements",
					key: "elements",
					mandatory: true,
					contentType: "components",
					whiteList: ["FullImage", "Video"],
					maxItems: 7,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "MasonryAudiovisual",
		elements: Array(7).fill({
			component: "FullImage",
			imageField: { url: "https://images.dev.griddo.io/secuoyas-gradient" },
			veil: 0,
		}),
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/MasonryAudiovisual/thumbnail@1x.png",
		"2x": "/thumbnails/modules/MasonryAudiovisual/thumbnail@2x.png",
	},
};

export default schema;
