import { Schema } from "@griddo/core";
import { themeSelector } from "@schemas/presets";

import { BasicCardProps } from "@/autotypes";

const schema: Schema.Component<BasicCardProps> = {
	schemaType: "component",
	component: "BasicCard",
	displayName: "BasicCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "RichText",
					title: "Number",
					key: "number",
					placeholder: "Type a detail text",
					isMockup: true,
					hideable: true,
					helptext: "Date, client...",
					humanReadable: true,
				},

				{
					type: "HeadingField",
					title: "Title",
					default: { content: "Lorem ipsum", tag: "h2" },
					key: "title",
					placeholder: "Type a title",
					advanced: true,
					hideable: true,
					isMockup: true,
					options: [
						{ label: "h2", value: "h2" },
						{ label: "h3", value: "h3" },
						{ label: "h4", value: "h4" },
					],
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content text",
					isMockup: true,
					hideable: true,
					humanReadable: true,
				},

				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "link",
					hideable: true,
					helptext: "The link of the card",
				},

				{
					type: "ComponentContainer",
					key: "media",
					title: "Media",
					whiteList: ["FullImage", "Video"],
				},

				{
					type: "TagsField",
					key: "tags",
					title: "Tags",
				},
			],
		},
		{
			title: "config",
			fields: [{ ...themeSelector }],
		},
	],

	default: {
		component: "BasicCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		subtitle: "",
		detail: "",
		link: { component: "Button" },
		media: {
			fullImage: {
				component: "FullImage",
			},
			video: {
				component: "Video",
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/components/BasicCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/BasicCard/thumbnail@2x.png",
	},
};

export default schema;
