import { Schema } from "@griddo/core";
import { anchor, heading, themeSelector } from "@schemas/presets";

import { InnerHeroProps } from "@/autotypes";

const schema: Schema.Module<InnerHeroProps> = {
	schemaType: "module",
	component: "InnerHero",
	category: "heros",
	displayName: "InnerHero",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					title: "Image",
					type: "ComponentContainer",
					key: "image",
					whiteList: ["FullImage"],
					hideable: true,
				},

				{
					title: "Mobile Image",
					type: "ComponentContainer",
					key: "mobileImage",
					whiteList: ["FullImage"],
					hideable: true,
				},

				{
					title: "Images",
					type: "ComponentArray",
					key: "images",
					contentType: "components",
					whiteList: ["Image"],
				},

				{
					type: "TagsField",
					title: "Clients",
					key: "clients",
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/InnerHero/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/InnerHero/layout2.png",
						},
					],
				},

				{ ...themeSelector },

				{
					type: "RadioGroup",
					key: "animation",
					title: "Animation",
					mandatory: true,
					options: [
						{
							name: "noAnimation",
							title: "No animation",
							value: "noAnimation",
						},
						{
							name: "clientAnimation",
							title: "Client Animation",
							value: "clientAnimation",
						},
						{
							name: "imageAnimation",
							title: "Image Animation",
							value: "imageAnimation",
						},
					],
				},
			],
		},
	],

	default: {
		component: "InnerHero",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		layout: "layout1",
		subtheme: "inverse",
		link: {
			component: "Button",
		},
		image: {
			component: "FullImage",
		},
		mobileImage: {
			component: "FullImage",
		},
		clients: [],
		images: [],
		animation: "noAnimation",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/InnerHero/thumbnail@1x.png",
		"2x": "/thumbnails/modules/InnerHero/thumbnail@2x.png",
	},
};

export default schema;
