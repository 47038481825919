import { Schema } from "@griddo/core";
import { anchor, heading, themeSelector } from "@schemas/presets";

import { HeroProjectProps } from "@/autotypes";

const schema: Schema.Module<HeroProjectProps> = {
	schemaType: "module",
	component: "HeroProject",
	category: "heros",
	displayName: "HeroProject",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
					// h3
				},

				{
					title: "Image",
					type: "ComponentContainer",
					mandatory: true,
					key: "image",
					whiteList: ["FullImage"],
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }],
		},
	],

	default: {
		component: "HeroProject",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		subtheme: "inverse",
		image: {
			component: "FullImage",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroProject/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroProject/thumbnail@2x.png",
	},
};

export default schema;
