import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import { CardCollectionSliderProps } from "@/autotypes";

const schema: Schema.Module<CardCollectionSliderProps> = {
	schemaType: "module",
	component: "CardCollectionSlider",
	category: "collection",
	displayName: "CardCollectionSlider",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},

				{
					type: "ComponentArray",
					title: "Cards",
					key: "cards",
					mandatory: true,
					contentType: "components",
					whiteList: [
						"TextCard",
						"BasicCard",
						"ImageCard",
						"AccordionCard",
						//
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },

				{
					type: "Select",
					title: "Card size",
					key: "cardSize",
					helptext: "The card size set the aspect-ratio",
					options: [
						{ label: "xs", value: "xs" },
						{ label: "sm", value: "sm" },
						{ label: "md", value: "md" },
						{ label: "lg", value: "lg" },
						{ label: "xl", value: "xl" },
					],
				},

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 3,
					mandatory: true,
					options: [
						{
							value: "vertical",
							img: "/thumbnails/modules/CardCollectionSlider/layouts/vertical.png",
						},
					],
				},

				{ ...themeSelector },

				{
					title: "Card Style",
					key: "kind",
					type: "VisualUniqueSelection",
					elementUniqueSelection: true,
					reference: "cards",
					mandatory: true,
					options: [
						{
							value: "BasicCard",
							img: "/thumbnails/modules/CardCollection/card-styles/basic-card.png",
						},
						{
							value: "AccordionCard",
							img: "/thumbnails/modules/CardCollection/card-styles/accordion-card.png",
						},
						{
							value: "ImageCard",
							img: "/thumbnails/modules/CardCollection/card-styles/image-card.png",
						},
						{
							value: "TextCard",
							img: "/thumbnails/modules/CardCollection/card-styles/text-card.png",
						},
					],
					columns: 3,
				},

				...verticalSpacing,
			],
		},
	],

	default: {
		component: "CardCollectionSlider",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		link: {
			component: "Button",
			variant: "primary",
			link: { text: "Ver más", linkType: "url", url: {}, modal: {} },
		},
		cardSize: "xs",
		kind: "TextCard",
		cards: [
			{
				component: "TextCard",
				logo: null,
				title: { content: "Lorem ipsum", tag: "h2" },
				content: "Lorem ipsum paragraph",
			},
		],
		topSpacing: "medium",
		bottomSpacing: "medium",
		layout: "vertical",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollectionSlider/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollectionSlider/thumbnail@2x.png",
	},
};

export default schema;
