import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import { FeaturedAudiovisualProps } from "@/autotypes";

const schema: Schema.Module<FeaturedAudiovisualProps> = {
	schemaType: "module",
	component: "FeaturedAudiovisual",
	category: "featured",
	displayName: "FeaturedAudiovisual",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},

				{
					title: "Elements",
					type: "ComponentArray",
					key: "elements",
					contentType: "components",
					whiteList: ["FullImage", "Video"],
					maxItems: 4,
				},

				{
					title: "Background image/video",
					type: "ComponentContainer",
					key: "bgMedia",
					whiteList: ["FullImage", "Video"],
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout4.png",
						},
						{
							value: "layout5",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout5.png",
						},
						{
							value: "layout6",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout6.png",
						},
						{
							value: "layout7",
							img: "/thumbnails/modules/FeaturedAudiovisual/layouts/layout7.png",
						},
					],
				},

				{
					type: "NumberField",
					key: "imagesRatio",
					title: "Images Container Aspect Ratio",
					min: 1,
				},

				{ ...themeSelector },

				...verticalSpacing,
			],
		},
	],

	default: {
		component: "FeaturedAudiovisual",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		link: {
			component: "Button",
			link: { text: "Saber más", linkType: "url", url: {}, modal: {} },
			variant: "cta",
		},
		elements: [],
		bgMedia: {
			fullImage: { component: "FullImage" },
			video: { component: "Video" },
		},
		topSpacing: "large",
		bottomSpacing: "large",
		layout: "layout1",
		subtheme: "default",
		imagesRatio: 1,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FeaturedAudiovisual/thumbnail@2x.png",
	},
};

export default schema;
