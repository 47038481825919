import { Schema } from "@griddo/core";
import Contact from "@schemas/modules/Contact";
import { allModules } from "@schemas/presets";

import { ContactTemplateProps } from "@/autotypes";

const schema: Schema.Template<ContactTemplateProps> = {
	schemaType: "template",
	displayName: "Contact",
	component: "ContactTemplate",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			contentType: "modules",
			maxItems: 1,
			whiteList: ["HeroVideo", "InnerHero", "CustomVideo"],
			key: "heroSection",
			mandatory: true,
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			contentType: "modules",
			whiteList: allModules,
			key: "mainSection",
		},

		{
			type: "ComponentContainer",
			title: "Contact Module",
			whiteList: ["Contact"],
			key: "contactModule",
			mandatory: true,
			hideable: false,
		},

		{
			type: "ComponentArray",
			title: "Related Content",
			contentType: "modules",
			whiteList: allModules,
			key: "relatedContent",
		},
	],

	default: {
		type: "template",
		templateType: "ContactTemplate",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
		contactModule: {
			...Contact.default,
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ContactTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ContactTemplate/thumbnail@2x.png",
	},
};

export default schema;
