import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

import { DigitalProductGraphicProps } from "@/autotypes";

const schema: Schema.Component<DigitalProductGraphicProps> = {
	schemaType: "component",
	component: "DigitalProductGraphic",
	displayName: "Digital Product Graphic",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ArrayFieldGroup",
					title: "Phases",
					key: "phases",
					name: "Phase",
					arrayType: "dropDown",
					fields: [
						{
							type: "TextField",
							title: "Name",
							key: "name",
							placeholder: "Type a phase name",
							isMockup: true,
							mandatory: true,
							humanReadable: true,
						},

						{
							type: "ArrayFieldGroup",
							title: "Steps",
							key: "steps",
							name: "Step",
							arrayType: "dropDown",
							fields: [
								{
									type: "TextField",
									title: "Name",
									key: "name",
									placeholder: "Type a step name",
									helptext: "Short title to show in the graphic",
									isMockup: true,
									mandatory: true,
									humanReadable: true,
								},

								{
									title: "Size",
									type: "NumberField",
									key: "size",
									validators: { minValue: 1 },
									mandatory: true,
									hideable: false,
								},

								{ ...heading },

								{
									type: "RichText",
									title: "Content",
									key: "content",
									placeholder: "Type a content",
									isMockup: true,
									mandatory: true,
									humanReadable: true,
								},
							],
						},
					],
				},
			],
		},
	],

	default: {
		component: "DigitalProductGraphic",
		phases: [],
	},

	thumbnails: {
		"1x": "/thumbnails/components/DigitalProductGraphic/thumbnail@1x.png",
		"2x": "/thumbnails/components/DigitalProductGraphic/thumbnail@2x.png",
	},
};

export default schema;
