import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

import { TextCardProps } from "@/autotypes";

const schema: Schema.Component<TextCardProps> = {
	schemaType: "component",
	component: "TextCard",
	displayName: "TextCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ImageField",
					title: "Logo",
					key: "logo",
					hideable: true,
					helptext: "Recommended use a vector or minimum image size: 120x120",
				},

				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content text",
					isMockup: true,
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "TextCard",
		logo: null,
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Lorem ipsum paragraph",
	},

	thumbnails: {
		"1x": "/thumbnails/components/TextCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/TextCard/thumbnail@2x.png",
	},
};

export default schema;
