import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import { DataCollectionProps } from "@/autotypes";

const schema: Schema.Module<DataCollectionProps> = {
	schemaType: "module",
	component: "DataCollection",
	category: "collection",
	displayName: "DataCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				// HEADER
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					isMockup: true,
					humanReadable: true,
				},

				// CARDS
				{
					type: "TextField",
					title: "Subtitle 1",
					key: "subtitleCollection1",
					placeholder: "Type a subtitle",
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentArray",
					title: "Cards Collection 1",
					key: "cardsCollection1",
					mandatory: true,
					contentType: "components",
					whiteList: ["DataCard"],
				},

				{
					type: "TextField",
					title: "Subtitle 2",
					key: "subtitleCollection2",
					placeholder: "Type a subtitle",
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentArray",
					title: "Cards Collection 2",
					key: "cardsCollection2",
					mandatory: true,
					contentType: "components",
					whiteList: ["DataCard"],
				},

				{ ...heading, title: "Title 2", key: "title2" },

				{
					type: "TextField",
					title: "Subtitle",
					key: "subtitle2",
					placeholder: "Type a subtitle",
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Intro",
					key: "intro2",
					placeholder: "Type a intro",
					isMockup: true,
					hideable: true,
					humanReadable: true,
				},

				{
					type: "RichText",
					title: "Content",
					key: "content2",
					placeholder: "Type a content",
					humanReadable: true,
					mandatory: true,
				},

				{
					title: "Link",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "link",
					hideable: true,
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "DataCollection",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		cardSize: "xs",
		kind: "BasicCard",
		cardsCollection1: [
			{
				component: "DataCard",
				title: { content: "Short card title", tag: "h2" },
				number: "123",
				content: "Content",
			},
		],
		cardsCollection2: [
			{
				component: "DataCard",
				title: { content: "Short card title", tag: "h2" },
				number: "123",
				content: "Content",
			},
		],
		link: {
			component: "Button",
			variant: "primary",
			link: { text: "Ver más", linkType: "url", url: {}, modal: {} },
		},
		topSpacing: "medium",
		bottomSpacing: "medium",
		layout: "layout1",
		subtheme: "default",
		fit: false,
		contentModule: {
			component: "BasicText",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/DataCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/DataCollection/thumbnail@2x.png",
	},
};

export default schema;
