import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

import { QuoteProps } from "@/autotypes";

const schema: Schema.Module<QuoteProps> = {
	schemaType: "module",
	component: "Quote",
	category: "content",
	displayName: "Quote",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Detail",
					key: "detail",
					placeholder: "Type a detail",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "Quote",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Quote/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Quote/thumbnail@2x.png",
	},
};

export default schema;
