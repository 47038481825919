import { Schema } from "@griddo/core";
import { anchor, verticalSpacing } from "@schemas/presets";

import { CustomImageProps } from "@/autotypes";

const schema: Schema.Module<CustomImageProps> = {
	schemaType: "module",
	component: "CustomImage",
	category: "content",
	displayName: "CustomImage",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Svg",
					key: "svg",
					type: "RadioGroup",
					mandatory: true,
					options: [
						{
							name: "formacionLogoSaddle",
							value: "formacionLogoSaddle",
							title: "Saddle formación logo",
						},
						{
							name: "logoNormalizadoSaddle",
							value: "logoNormalizadoSaddle",
							title: "Saddle logo normalizado",
						},
						{
							name: "competenciaSaddle",
							value: "competenciaSaddle",
							title: "Saddle competencia",
						},
						{
							name: "carrefourMovilInicio",
							value: "carrefourMovilInicio",
							title: "Carrefour movil inicio",
						},
						{
							name: "carrefourComentarios",
							value: "carrefourComentarios",
							title: "Carrefour comentarios",
						},
						{
							name: "carrefourIlustracionIsometricDesktop",
							value: "carrefourIlustracionIsometricDesktop",
							title: "Carrefour ilustración isometric desktop",
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, ...verticalSpacing],
		},
	],

	default: {
		component: "CustomImage",
		topSpacing: "small",
		bottomSpacing: "small",
		svg: "formacionLogoSaddle",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CustomImage/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CustomImage/thumbnail@2x.png",
	},
};

export default schema;
