import { Schema } from "@griddo/core";

import { DevOpsGraphicProps } from "@/autotypes";

const schema: Schema.Component<DevOpsGraphicProps> = {
	schemaType: "component",
	component: "DevOpsGraphic",
	displayName: "DevOps Graphic",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "First Phase Title",
					key: "phase1",
					placeholder: "Type a phase title",
					mandatory: true,
					humanReadable: true,
					isMockup: true,
				},

				{
					type: "TextField",
					title: "Second Phase Title",
					key: "phase2",
					placeholder: "Type a phase title",
					mandatory: true,
					humanReadable: true,
					isMockup: true,
				},

				{
					type: "ArrayFieldGroup",
					title: "Steps",
					key: "steps",
					name: "Step",
					arrayType: "dropDown",
					fields: [
						{
							type: "TextField",
							title: "Content",
							key: "content",
							placeholder: "Type a content",
							mandatory: true,
							humanReadable: true,
						},
					],
				},
			],
		},
	],

	default: {
		component: "DevOpsGraphic",
		phase1: "Lorem ipsum",
		phase2: "Lorem ipsum",
		steps: [],
	},

	thumbnails: {
		"1x": "/thumbnails/components/DevOpsGraphic/thumbnail@1x.png",
		"2x": "/thumbnails/components/DevOpsGraphic/thumbnail@2x.png",
	},
};

export default schema;
