import { Schema } from "@griddo/core";

import { Error404Props } from "@/autotypes";

const schema: Schema.Template<Error404Props> = {
	schemaType: "template",
	displayName: "Error 404",
	component: "Error404",
	type: { label: "Static", value: "static", special: "404" },
	singleInstance: true,

	content: [
		{
			type: "TextField",
			title: "Subtitle",
			key: "subtitle",
			placeholder: "Type your subtitle",
			humanReadable: true,
		},

		{
			type: "RichText",
			title: "Content",
			key: "content",
			placeholder: "Type your content",
			humanReadable: true,
		},

		{
			type: "ComponentContainer",
			key: "image",
			title: "Background Image",
			whiteList: ["FullImage"],
		},

		{
			title: "Button",
			type: "ComponentContainer",
			whiteList: ["Button"],
			key: "link",
			hideable: true,
		},
	],

	default: {
		type: "template",
		templateType: "Error404",
		subtitle: "Lorem ipsum paragraph",
		content: undefined,
		image: {
			component: "FullImage",
		},
		link: {
			component: "Button",
			variant: "cta",
			showLeftIcon: true,
			leftIcon: "arrowLeft",
			link: {
				linkType: "url",
				text: "Volver",
				url: {
					linkToURL: "/",
				},
				modal: {},
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Error404/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Error404/thumbnail@2x.png",
	},
};

export default schema;
